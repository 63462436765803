
import Head from "next/head"

export default function Head_title ({title}) {
    return (
    <>
        <Head>
            <title>{title}</title>
            <link rel="icon" href="/hot_bubble.ico" />
        </Head>
    </>

    )
}
