import React, { useContext } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import Title from './Title'
import Themebuttons from './Themebuttons';



export default function Thememenu({setTheme}) {

return (
    <div className={"fixed overflow-auto top-0 left-0 w-screen h-screen z-50 after:content-[''] after:w-screen after:h-screen after:bg-white after:absolute after:top-0 after:left-0 after:opacity-60 grid place-content-center"}>
        <OutsideClickHandler onOutsideClick={() => setTheme(false)}>  
        <div className='relative w-full h-full grid place-content-center'>
          <div className="relative z-50 md:w-[26rem] w-[23.125rem] bg-white border-2 p-10 rounded-3xl">
              <Title addClass={"text-[2.5rem] text-black font-dancing text-center"} baslik={"Tema seçin"} />
              <Themebuttons/>
            </div>
        </div>
        </OutsideClickHandler>
    </div>
  )
}