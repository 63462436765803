import Image from 'next/image'
import OutsideClickHandler from 'react-outside-click-handler'
import Title from './Title'



export default function Searchmenu({setSearch}) {

  const data = [
  { id: 1, im: '/images/dilim.png', na: "Pasta", para: "10$" },
  { id: 2, im: '/images/dilim.png', na: "Pizza", para: "20$" },
  { id: 3, im: '/images/dilim.png', na: "Ekmek", para: "30$" },
  { id: 4, im: '/images/hamburger2.png', na: "Peynir", para: "40$" },
  { id: 5, im: '/images/hamburger3.png', na: "Zeytin", para: "50$" },
  { id: 6, im: '/images/hamburger4.png', na: "Çay", para: "60$" },
  { id: 7, im: '/images/kasarlimakarna.png', na: "Reçel", para: "70$" },
  { id: 8, im: '/images/sebzelipizza.png', na: "Bal", para: "80$" },
  { id: 9, im: '/images/yesilpizza.png', na: "Yağ", para: "90$" },
  { id: 10, im: '/images/zeytinlimakarna.png', na: "Domates", para: "100$" },
  { id: 11, im: '/images/hamburgervektor.png', na: "Salatalık", para: "110$" },
  { id: 12, im: '/images/dilim.png', na: "Labne", para: "120$" },
  { id: 13, im: '/images/dilim.png', na: "Çeçil Peyniri", para: "130$" },
  { id: 14, im: '/images/dilim.png', na: "Patates Kızartması", para: "140$" },
  { id: 15, im: '/images/dilim.png', na: "Kadınbudu Köfte", para: "150$" },
]
function liste({id, im, na, para}) {
    return (
      <li key={id} className='flex items-center justify-between p-3 hover:bg-primary transition-all hover:rounded-lg'>
        <div className='relative flex w-10 h-10 border-none '>
          <Image src={im} alt="" layout='fill' />
        </div>
        <span className='text-black font-bold'>{na}</span>
        <span className='text-black font-bold'>{para}</span>
      </li>
    )
} 

return (
    <div className={"fixed top-0 left-0 w-screen h-screen z-50 after:content-[''] after:w-screen after:h-screen after:bg-white after:absolute after:top-0 after:left-0 after:opacity-60 grid place-content-center"}>
        <OutsideClickHandler onOutsideClick={() => setSearch(false)}>  
        <div className='relative w-full h-screen grid place-content-center'>
          <div className="relative z-50 md:w-[36rem] w-[23.125rem] md:h-[36rem] h-[23.125rem] bg-white border-2 p-10 rounded-3xl">
              <Title addClass={" md:text-[2.5rem] text-[1.8rem] text-black font-dancing text-center"} baslik={"Search"} />
              <input type="text" placeholder='Search...' className='border w-full my-4'/>
                
               <div className="relative overflow-auto md:h-[25rem] h-48">
                 <ul>
                   {
                   data.map((id, im, na, para) => (
                       liste(id=id, im=im,na=na,para=para)
                     ))
                   }
                 </ul>
               </div>
                
            </div>
        </div>
        </OutsideClickHandler>
    </div>
  )
}

