    import Title from "./Title"
    import {FaFacebookSquare,FaTwitter,FaInstagram} from "react-icons/fa"
    import HeaderBgContext from './context/HeaderBgContext';
import { useContext } from "react";
    
    export default function Footer() {

      const {isBg} = useContext(HeaderBgContext);

        return (
          <footer>
          <div className={"footer"+" "+ isBg}>
            <div className="container mx-auto pt-6 pb-6 mb-0">
              <div className="flex md:justify-between justify-center text-center flex-wrap md:gap-y-0 gap-y-6 ">
                <div className="md:flex-1">
                  <Title baslik="Contact Us" addClass="text-[30px] font-dancing" />
                  <div className="flex flex-col gap-y-2 mt-3 text-sm">
                    <div>
                      <i className="fa fa-map-marker"></i>
                      <span className="inline-block ml-2">Location</span>
                    </div>
                    <div>
                      <i className="fa fa-phone"></i>
                      <span className="inline-block ml-2">Call +01 1234567890</span>
                    </div>
                    <div>
                      <i className="fa fa-envelope"></i>
                      <span className="inline-block ml-2">demo@gmail.com</span>
                    </div>
                  </div>
                </div>
                <div className="md:flex-1">
                  <Title baslik="Feane" addClass="text-[30px] font-dancing" />
                  <p className="mt-3 text-sm">
                    Necessary, making this the first true generator on the Internet.
                    It uses a dictionary of over 200 Latin words, combined with
                  </p>
                  <div className="flex items-center justify-center mt-5 gap-x-2">
                    <a
                      href=""
                      className="w-8 h-8 grid place-content-center bg-white text-secondary rounded-full "
                    >
                      <FaFacebookSquare />
                    </a>
                    <a
                      href=""
                      className="w-8 h-8 grid place-content-center bg-white text-secondary rounded-full"
                    >
                      <FaTwitter />
                    </a>
                    <a
                      href=""
                      className="w-8 h-8 grid place-content-center bg-white text-secondary rounded-full"
                    >
                      <FaInstagram />
                    </a>
                  </div>
                </div>
                <div className="md:flex-1">
                  <Title baslik="Opening Hours" addClass="text-[30px] font-dancing" />
                  <div className="flex flex-col gap-y-2 mt-3 text-sm">
                    <div>
                      <span className="inline-block ml-2">Everyday</span>
                    </div>
                    <div>
                      <span className="inline-block ml-2">10.00 Am -10.00 Pm</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </footer>
        )
      }