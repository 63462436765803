import '../styles/globals.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from 'react'
import HeaderBgContext from '../components/context/HeaderBgContext'
import Head_title from '../components/Head'
import Layout from '../layout/Layout';
import { Provider } from 'react-redux';
import store from '../redux/store';
import { initGA, logPageView } from '../utils/analytics';
import { useRouter } from 'next/router';
import { SessionProvider } from "next-auth/react"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const router = useRouter();
  /*Alttaki props lar contextler için gerekli */
  const [isBg, setBg] = useState("witching_hour");

  // useEffect(() => {
  //   if (!window.GA_INITIALIZED) {
  //     initGA('G-2ZL5EJY74V');
  //     window.GA_INITIALIZED = true;
  //   }
  //   logPageView();
  // }, []);

  return (
    <>
      <SessionProvider session={session}>
        <Provider store={store}>
          <HeaderBgContext.Provider value={{ isBg, setBg }}>
            <Head_title title={router.pathname === '/' ? 'Anasayfa' : router.pathname.split('/').pop()} />
            <Layout>
              <Component {...pageProps} />
              <ToastContainer />
            </Layout>
          </HeaderBgContext.Provider>
        </Provider>
      </SessionProvider>
    </>
  )
}

export default MyApp