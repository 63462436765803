import NavBarItem from "./Navigation_bar_item";
import { GiCancel } from "react-icons/gi";

export default function Navigation_bar({isBurgerMenu, setBurgerMenu}) {

    const data = [
        { id: 1, text: 'Home', href: '/' },
        { id: 2, text: 'Menu', href: '/menu' },
        { id: 3, text: 'About', href: '/about' },
        { id: 4, text: 'Book Table', href: '/booktable' },
    ]

    function liste ({id,text,href}) {
     return (
        <li key={id}><NavBarItem text={text} href={href} /></li>
        )
    }

    return (
        <nav className={`sm:static absolute top-0 left-0 
        sm:w-auto sm:h-auto h-screen w-full sm:text-white text-black sm:bg-transparent bg-white
        sm:flex hidden
        ${isBurgerMenu === true && "!grid place-content-center text-bold"}`} >
            <ul className={"flex items-center gap-x-2 sm:flex-row flex-col font-dancing text-xl"}>
                {
                    data.map((id,text,href) => (
                        liste(id=id,text=text,href=href)
                      ))
                }
            </ul>  
            {
                isBurgerMenu && (
                  <button className="absolute top-4 right-4 z-50" onClick={() => setBurgerMenu(false)} >
                      <GiCancel size={25} className="transition-all"/>
                  </button>
                )
              }
        </nav>
    )
}