import Link from "next/link";
import { useRouter } from 'next/router'

export default function NavBarItem ({text,href}) {
    const router = useRouter();
    const currentRoute = router.pathname;
    const act = (currentRoute === href ? "active" : "non-active");

    return (
    <>
        <Link href={href} >
                 <a className={"navitem " + act} > {/*onClick={() => setBurgerMenu(false)}> */}
                    {text}
                </a>
        </Link>
        <style jsx> {`
            .navitem {
                display: flex;
                align-items: center;
                padding-left: 30px;
                padding-right: 30px;
                height: 100%;
                text-decoration: none;
            }

            .navitem:hover {
                color: orange;
              }

            .active {
            color: orange;
            }
        `
        }
        </style>
    </>
        
    );
};