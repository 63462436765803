import { useContext } from 'react';
import styles from '../styles/components/Buttons.module.css'
import HeaderBgContext from './context/HeaderBgContext';

export default function Themebuttons() {
  const {setBg} = useContext(HeaderBgContext);
  

  const data = ["lawrencium","witching_hour","amin","yoda","dark_ocean","moonlit_asteroid"];

  function testButton ({item}) {
    return <button key={item} className={styles["btn"] + " " + item} onClick={() => setBg(item)}>{item}</button>;
  }

  return (
    <>

      <div className='flex flex-col my-8 gap-x-8'>
          {
          data.map((item) => (
                              testButton(item={item})
                        ))
          }
      </div>
    </>
  )



}
