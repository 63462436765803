import { useSelector } from "react-redux";
import Link from "next/link"

export default function Right_navbar_item({text,href,sayac}) {
  const cart = useSelector((state) => state.cart);

  return (
    <>
        <Link href={href}>
              <a className="hover:text-primary">
                {
                  sayac == 1 &&
                    <span className="w-4 h-4 text-xs grid place-content-center absolute top-6 right-[368px] rounded-full bg-primary text-black font-bold ">
                      {cart.products.length}
                    </span>
                }
                {text}
                
              </a>
        </Link>
    </>
  )
}
