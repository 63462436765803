import { useContext, useState } from 'react';
import HeaderBgContext from './context/HeaderBgContext';
import Logo from './Logo'
import Navigation_bar from './Navigation_bar'
import Searchmenu from './Searchmenu';
import Thememenu from './Thememenu';
import Right_navbar_item from "./Right_navbar_item"
import {FaUserAlt,FaShoppingCart,FaSearch,FaAffiliatetheme} from "react-icons/fa"
import {GiHamburgerMenu} from "react-icons/gi"
import Button_primary from "./items/Button_primary"


export default function Header() {

  const {isBg} = useContext(HeaderBgContext);
  const [isSearch, setSearch] = useState(false);
  const [isTheme, setTheme] = useState(false);
  const [isBurgerMenu, setBurgerMenu ]= useState(false);

  // 1. useContext componentler arasında props kullanmadan direk değişkeni kullanmayı sağladı.
  // 2. components>SearchContext içerisinde createContext yaparak contexti oluşturduk.
  // 3. _app.js genel page içerisine SearchContext import ederek, SearchContext.Provider aracılığı ile belirlenen değişken ve metodu gerekli yerlerden dinlemesini sağladık. 
  //   3.1. Burada genel sarmalama yaparak tüm componentlerin bu değişken ve metoda erişebilmesini sağladık.
  // 4. Gerekli alt componentlerde useContext i kullanarak istediğimiz contextin istediğimiz değişken veya metotuna erişebilir hale geldik.
  //   4.1. useContext te hangi contextin kullanılacağını belirtmek için SearchContext i tekrar import etmek gerekiyor.
  //   4.2. _app.js de sarmalanan context ile aşağıdaki componentler nelere erişmiş oldu ?
  //        • Header componenti useContext ile isSearch değişkenine
  //        • Right_navigation_bar componenti useContext ile setSearch metoduna
  //        • Searchmenu componenti useContext ile setSearch metoduna
  // 5. Bu şekilde genel context sayesinde farklı seviyedeki componetler aynı değişkeni veya metotu kullanarak hareket etmiş oldu. 
  //   5.1. Böylece props aktara aktara props ağacı oluşturmamıza gerek kalmadı. Bu duruma props delme deniyor.
  // 6. Her component de aynı değişkeni ve metotu kullanabilmek için _app.js de sarmalama yapmak zorundayız.

    return (
    <>
      <header className={"header"+" "+ isBg}>
        <div className="container mx-auto flex justify-between items-center h-full text-white">
              <div>
                <Logo/>
              </div>
                <Navigation_bar isBurgerMenu={isBurgerMenu} setBurgerMenu={setBurgerMenu}/>
              <div className="flex flex-row items-center gap-x-4">
                {/* <Right_navigation_bar/> bu şekilde import ettim, context kullandığım için sistemi yavaşlattı. O yüzden component birleştirip değişkenleri props olarak verdim.*/}
                <FaAffiliatetheme className="md:inline-block hidden cursor-pointer hover:text-primary" onClick={() => setTheme(true)}/>
                <nav className={"flex gap-x-4 items-center"}>
                  <Right_navbar_item text={<FaUserAlt/>} href={'/auth/login'} />
                  <Right_navbar_item text={<FaShoppingCart/>} href={'/cart'} sayac="1" />
                  <Right_navbar_item text={<FaSearch onClick={() => setSearch(true)}/>} href={'/'} />
                  <Right_navbar_item text={<Button_primary label={'Order Online'}/>} href={'/'} />
                  <button className="sm:hidden inline-block" onClick={() => setBurgerMenu(true)} >
                    <GiHamburgerMenu className="text-xl hover:text-primary transition-all" />
                  </button>
                </nav>
              </div>
              {isSearch && <Searchmenu setSearch={setSearch}/>}
              {isTheme && <Thememenu setTheme={setTheme}/>}
        </div>
        
      </header>
    </>
    )
}